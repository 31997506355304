
export default {
  data() {
    return {
      options: [
        {
          name: "logo",
          label: "Logo",
          type: "image",
          size: "316x184",
        },
        {
          name: "hashtag",
          label: "Hashtag",
          type: "text",
        },
        {
          name: "title",
          label: "Naslov",
          type: "text",
        },
        {
          name: "description",
          label: "Opis",
          type: "text",
        },

        {
          name: "link",
          label: "Poveznica",
          type: "link",
        },
      ],
    };
  },
};
