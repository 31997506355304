import { render, staticRenderFns } from "./EventsSection.vue?vue&type=template&id=34e7934a"
import script from "./EventsSection.vue?vue&type=script&lang=js"
export * from "./EventsSection.vue?vue&type=script&lang=js"
import style0 from "./EventsSection.vue?vue&type=style&index=0&id=34e7934a&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiTitleLineComponent: require('/home/node/app/components/ui/titleLineComponent.vue').default,ControllerCalendar: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/ControllerCalendar.js').default})
