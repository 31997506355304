
export default {
  data() {
    return {
      categories: [],
    };
  },

  mounted() {
    setTimeout(() => {
      // set active category
      if (
        this.$refs.categories &&
        this.$refs.categories._data &&
        this.$refs.categories._data.postCategories &&
        this.$refs.categories._data.postCategories.length
      ) {
        this.$refs.categories._data.postCategories.forEach((category) => {
          if (this.categories.length === 0 && category.postCount > 0) {
            this.categories.push(category.slug);
          }
        });
      }
    }, 700);
  },
  methods: {
    appendCategory(category) {
      if (category === null) {
        this.categories.splice(0, this.categories.length);
        return false;
      }
      this.categories.splice(this.categories.indexOf(category.slug), 1);
      this.categories.push(category.slug);
    },
  },
};
