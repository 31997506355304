
export default {
  data() {
    return {
      newLine: null,
      categories: [],
      activeCategory: null,
      limit: 10,
      isLoaded: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.newLine = this.$checkIfNewLine(this.id);

      if (
        this.$refs.categories &&
        this.$refs.categories._data &&
        this.$refs.categories._data.postCategories &&
        this.$refs.categories._data.postCategories.length &&
        this.$route &&
        this.$route.params &&
        this.$route.params.slug
      ) {
        this.$refs.categories._data.postCategories.forEach((category) => {
          if (category.slug === this.$route.params.slug) {
            this.categories.push(category.slug);
            this.activeCategory = category.title;
          }

          setTimeout(() => {
            this.isLoaded = true;
          }, 1000);
        });
      } else {
        setTimeout(() => {
          this.isLoaded = true;
        }, 1000);
      }
    }, 500);
  },
};
