
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";

export default {
  components: { VueSlickCarousel },
  data() {
    return {
      acitveFilterIndex: 0,
      CmsEvents: [],
      carouselKey: 0,

      fitlers: [
        {
          id: "todayFilter",
          title: "Danas",
        },
        {
          id: "weekFilter",
          title: "Ovaj tjedan",
        },
        {
          id: "monthFilter",
          title: "Ovaj mjesec",
        },
        {
          id: "nextMonthFilter",
          title: "Sljedeći mjesec",
        },
      ],

      settings: {
        infinite: false,

        arrows: false,
        dots: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 550,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              fade: true,
            },
          },
        ],
      },
    };
  },
  mounted() {
    setTimeout(() => {
      this.filterTodaysEvent();
    }, 500);
  },
  watch: {
    CmsEvents() {
      this.forceRerender();
    },
  },
  methods: {
    forceRerender() {
      this.carouselKey += 1;
    },
    filterEvents(form, index) {
      if (!form || index === "undefined") return;

      this.acitveFilterIndex = index;

      switch (index) {
        case 0:
          this.filterTodaysEvent();
          break;
        case 1:
          this.filterWeek(form);
          break;
        case 2:
          this.filterMonth(form);
          break;
        case 3:
          this.filterMonth(form, true);
          break;
        default:
          return;
      }
    },
    filterTodaysEvent() {
      if (this.$refs.calendar) {
        this.$refs.calendar.filterToday();
      }
    },
    filterWeek(form) {
      if (!form) return;

      const date = new Date();

      var first = date.getDate() - date.getDay() + 1;
      var last = first + 6;

      var firstday = new Date(date.setDate(first));
      var lastday = new Date(date.setDate(last));

      firstday = this.convertDateToTimestamp(firstday);
      lastday = this.convertDateToTimestamp(lastday);

      form.startDate = firstday;
      form.endDate = lastday;
    },

    filterMonth(form, nextMonth = false) {
      if (!form) return;

      const date = new Date();
      let first, last;

      if (nextMonth) {
        first = new Date(date.getFullYear(), date.getMonth() + 1, 1);
        last = new Date(date.getFullYear(), date.getMonth() + 2, 0);
      } else {
        first = new Date(date.getFullYear(), date.getMonth(), 1);
        last = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      }

      const firstday = this.convertDateToTimestamp(first);
      const lastday = this.convertDateToTimestamp(last);

      form.startDate = firstday;
      form.endDate = lastday;
    },

    convertDateToTimestamp(date) {
      if (!date) return;

      const timestamp = date.getTime();

      return timestamp;
    },
  },
};
