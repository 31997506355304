
export default {
  props: {
    type: {
      type: String,
      default: "text",
    },
    error: {
      type: [Boolean],
      default: false,
    },
    showError: {
      type: [Boolean],
      default: false,
    },
    errorMsg: {
      type: [String],
      default: "",
    },
    value: {
      type: [String, Number],
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    textarea: {
      type: Boolean,
      default: false,
    },
    inputId: {
      type: String,
      required: true,
    },
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    inputClasses() {
      let classes;
      if (this.error) {
        classes = "border-secondary-red";
      } else classes = "border-secondary-gray-100";

      return classes;
    },
  },
};
