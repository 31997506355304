import { render, staticRenderFns } from "./PrivacyPolicy.vue?vue&type=template&id=1b81c8b8"
import script from "./PrivacyPolicy.vue?vue&type=script&lang=js"
export * from "./PrivacyPolicy.vue?vue&type=script&lang=js"
import style0 from "./PrivacyPolicy.vue?vue&type=style&index=0&id=1b81c8b8&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiTitleLineComponent: require('/home/node/app/components/ui/titleLineComponent.vue').default,EditorTipTap: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/editors/EditorTipTap.js').default,EditorList: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/editors/EditorList.js').default})
