
export default {
  data() {
    return {
      newLine: null,
      tags: [],
      selectedTag: null,
      items: [],
      limit: 10,
    };
  },
  mounted() {
    this.fetchCategoryTerms();
    if (this.$route.query && this.$route.query.tag) {
      this.selectedTag = this.$route.query.tag;
    }
    setTimeout(() => {
      this.newLine = this.$checkIfNewLine(this.id);

      if (
        this.$refs.posts &&
        this.$refs.posts.items &&
        this.$refs.posts.items.length
      ) {
        this.items = this.$refs.posts.items;
      }
    }, 500);
  },
  computed: {
    filteredItems: function () {
      var arr = [];
      if (this.items) {
        const parsedPosts = JSON.parse(JSON.stringify(this.items));
        parsedPosts.forEach((post) => {
          const tags = post.terms.tags;
          if (tags && tags.length) {
            tags.forEach((tag) => {
              if (this.selectedTag === tag.title) {
                arr.push(post);
                arr = JSON.parse(JSON.stringify(arr));
              }
            });
          }
        });
      }
      return arr;
    },
  },

  methods: {
    async fetchCategoryTerms() {
      const res = await this.$api.cms.term.filter({
        limit: 0,
        taxonomySlugs: ["tag"],
      });

      if (res.errored) return;
      const tags = this.tags;
      tags.splice(0, tags.length, ...res.data.data.tags);
    },
    appendTag(tag) {
      if (!tag) return;
      this.selectedTag = tag.title;
      if (this.tags.includes(tag.slug)) return;
      this.tags.push(tag.slug);
    },
  },
};
