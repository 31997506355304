
import s0 from '~/.nuxt/gb-cms/sections/CategoryPosts'
import s1 from '~/.nuxt/gb-cms/sections/FilterPostTag'
import s2 from '~/.nuxt/gb-cms/sections/EventsSection'
import s3 from '~/.nuxt/gb-cms/sections/GoogleAdSense'
import s4 from '~/.nuxt/gb-cms/sections/NewsletterSection'
import s5 from '~/.nuxt/gb-cms/sections/PhotoOfTheDay'
import s6 from '~/.nuxt/gb-cms/sections/PodcastSection'
import s7 from '~/.nuxt/gb-cms/sections/PopularPosts'
import s8 from '~/.nuxt/gb-cms/sections/QuoteSection'
import s9 from '~/.nuxt/gb-cms/sections/SelectedPosts'
import s10 from '~/.nuxt/gb-cms/sections/PrivacyPolicy'
import s11 from '~/.nuxt/gb-cms/sections/SingleEvent'

const sectionMapper = {
	'category-posts': s0,
	'filter-post-tag': s1,
	'events-section': s2,
	'google-ad-sense': s3,
	'newsletter-section': s4,
	'photo-of-the-day': s5,
	'podcast-section': s6,
	'popular-posts': s7,
	'quote-section': s8,
	'selected-posts': s9,
	'privacy-policy': s10,
	'single-event': s11
}

export default sectionMapper
