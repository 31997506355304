import { render, staticRenderFns } from "./QuoteSection.vue?vue&type=template&id=4f688ff2"
import script from "./QuoteSection.vue?vue&type=script&lang=js"
export * from "./QuoteSection.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiTitleLineComponent: require('/home/node/app/components/ui/titleLineComponent.vue').default,EditorImage: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/editors/EditorImage.js').default,EditorTipTap: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/editors/EditorTipTap.js').default})
