
export default {
  data() {
    return {
      activeIndex: 0,
      options: [
        {
          name: "category",
          label: "Kategorija videa",
          type: "text",
        },
        {
          name: "title",
          label: "Naziv videa",
          type: "text",
        },
        {
          name: "id",
          label: "ID videa",
          type: "text",
        },
      ],
    };
  },
};
