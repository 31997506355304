
export default {
  data() {
    return {
      hover: false,
      showHover: false,
    };
  },
  mounted() {
    this.$nextTick(function () {
      this.checkWindowWidth();
    });
    window.addEventListener("resize", this.checkWindowWidth, {
      passive: true,
    });
  },
  methods: {
    checkWindowWidth() {
      if (window.innerWidth < 640) {
        this.showHover = true;
      } else this.showHover = false;
    },
  },
};
