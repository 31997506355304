
export default {
  data() {
    return {
      halfBoxHeight: 0,
    };
  },
  mounted() {
    setTimeout(() => {
      this.getBoxHalfHeight();
    }, 300);
  },

  methods: {
    getBoxHalfHeight() {
      if (document.getElementById("quoteBox")) {
        const boxHeight = document.getElementById("quoteBox").offsetHeight;

        if (window.innerWidth < 640) {
          this.halfBoxHeight = 40;
        } else this.halfBoxHeight = boxHeight / 2;
      }
    },
  },
};
