import { render, staticRenderFns } from "./PopularPosts.vue?vue&type=template&id=57f8f266"
import script from "./PopularPosts.vue?vue&type=script&lang=js"
export * from "./PopularPosts.vue?vue&type=script&lang=js"
import style0 from "./PopularPosts.vue?vue&type=style&index=0&id=57f8f266&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiTitleLineComponent: require('/home/node/app/components/ui/titleLineComponent.vue').default,ControllerPostCategoryList: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/ControllerPostCategoryList.js').default,UiIconsEye: require('/home/node/app/components/ui/icons/eye.vue').default,ControllerPostList: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/ControllerPostList.js').default})
