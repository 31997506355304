var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container mb-16 md:mb-32",class:_vm.$isInEditMode ? 'pt-20' : 'pt-8'},[_c('ui-title-line-component',{attrs:{"id":"sectionTitle1"}}),_vm._v(" "),_c('div',{staticClass:"mt-8"},[_c('editor-post-list',{staticClass:"grid grid-cols-12 md:grid-rows-3 gap-y-4 sm:gap-x-4 md:gap-6 md:min-h-152.75",attrs:{"name":"separatedPosts"},scopedSlots:_vm._u([{key:"list",fn:function({ items }){return _vm._l((items.slice(0, 4)),function(item,index){return _c('div',{key:item.id,staticClass:"relative min-h-62.5 md:min-h-0 group overflow-hidden grayscale-item",class:index === 0
              ? 'col-span-12 sm:col-span-6 md:row-span-3'
              : index === 1 || index === 2
              ? 'lg:-mb-10 col-span-12 sm:col-span-6 lg:col-span-3 md:row-span-1'
              : 'lg:mt-10 col-span-12 sm:col-span-6 md:row-span-1 lg:row-span-2'},[_c('cms-link',{attrs:{"to":item,"title":item.title || 'title',"ondragstart":"return false;"}},[_c('cms-image',{staticClass:"absolute inset-0 w-full h-full transform md:group-hover:scale-110 transition-transform duration-500 img-cover",attrs:{"media":item.media,"custom-size":"MediumSize"}}),_vm._v(" "),_c('div',{staticClass:"absolute inset-0 w-full h-full bg-gradient"}),_vm._v(" "),_c('div',{staticClass:"relative flex flex-col justify-end h-full text-white",class:index === 0
                  ? 'p-6 lg:p-10'
                  : index === 1 || index === 2
                  ? 'p-4 lg:p-6'
                  : 'p-5 lg:p-8'},[(
                  item.terms &&
                  item.terms.categories &&
                  item.terms.categories.length &&
                  item.terms.categories[0]
                )?_c('span',{staticClass:"text-xs font-Gabarito font-semibold uppercase letter-20"},[_vm._v("\n                #"+_vm._s(item.terms.categories[0].title)+"\n              ")]):_vm._e(),_vm._v(" "),_c('span',{staticClass:"block mt-2 text-limit-3 font-bold uppercase font-variant",class:index === 0
                    ? 'text-lg md:text-4xl lg:text-40'
                    : index === 1 || index === 2
                    ? 'text-lg'
                    : 'text-lg lg:text-32',staticStyle:{"letter-spacing":"1px"}},[_vm._v("\n                "+_vm._s(item.title)+"\n              ")])])],1)],1)})}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }