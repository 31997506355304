
export default {
  data() {
    return {
      newLine: null,
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },

  mounted() {
    setTimeout(() => {
      this.newLine = this.$checkIfNewLine(this.id);
    }, 300);
  },
};
